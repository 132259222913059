import { Icon, Image } from "./common";

export interface MultiDayDescription {
  title: string;
  description: string;
  image?: Image;
}

export interface LibraryVendor {
  id: string;
  heliosItemId?: string;
  type?: VendorType;
  name: string;
  address?: string;
  city?: string;
  country?: string;
  phoneNumber?: string;
  email?: string;
  photos?: Image[];
  description?: string;
  websiteUrl?: string;
  // brandGroup and affiliations are technically a list of types and could be typed... leaving them as strings for now
  brandGroup?: string;
  affiliations?: string;
  informations?: string;
}

export interface LibraryService {
  id: string;
  heliosItemId?: string;
  name: string;
  type: ItineraryServiceType;
  subType?: ItineraryServiceSubtype;
  provider?: string; // ID reference to Partner
  address?: string;
  city?: string;
  country?: string; // ISO 3166-1 alpha-2 country code
  photos?: Image[];
  description?: string;
  multiDay?: boolean;
  multiDayDescriptions?: MultiDayDescription[];
  informations?: string;
}

export const DMC = "DMC";
export const HOTEL = "Hotel";
export const CRUISE = "Cruise";
export const OTHER = "Other";

export type VendorType =
  | typeof DMC
  | typeof HOTEL
  | typeof CRUISE
  | typeof OTHER;

export const ACCOMMODATION = "Accommodation"; // are these all caps or no?
export const TRANSPORT = "Transport";
export const ACTIVITY = "Activity";
export const INTRO = "Intro";
export type ItineraryServiceType =
  | typeof ACCOMMODATION
  | typeof TRANSPORT
  | typeof ACTIVITY
  | typeof INTRO;

export const CAR = "Car";
export const WALK = "Walk";
export const FERRY = "Ferry";
export const BIKE = "Bike";
export const TRAIN = "Train";
export const FLIGHT = "Flight";

export type ItineraryServiceSubtype =
  | typeof CAR
  | typeof WALK
  | typeof FERRY
  | typeof BIKE
  | typeof TRAIN
  | typeof FLIGHT;

export type FirebaseTimestamp = {
  seconds: number;
  nanoseconds: number;
  toJSDate: () => Date;
  toDate: () => Date;
};

export type ItineraryService = {
  id: string;
  name: string;
  description?: string;
  type: ItineraryServiceType;
  subType?: ItineraryServiceSubtype;
  startTime: FirebaseTimestamp;
  endTime: FirebaseTimestamp;
  quantity: number;
  providerId?: string;
  libraryServiceId: string;
  libraryServiceData?: LibraryService; // only populated when we are creating a Itinerary Service from a Library Service
};

export const UNLINKEDBLOCKS = "UnlinkedBlocks";

export type UnlinkedBlocks = typeof UNLINKEDBLOCKS;

export type ItineraryItemType = ItineraryServiceType | UnlinkedBlocks;

export type ItineraryItem = {
  id: string;
  type: ItineraryItemType;
  name: string;
  icon: Icon;
  blocks: string[];
  nightInfo?: {
    current: number;
    total: number;
  };
  itineraryServiceId?: string;
};

export const DAY_SECTION = "DaySection";
export const OTHER_SECTION = "OtherSection";

export type ItinerarySectionType = typeof DAY_SECTION | typeof OTHER_SECTION;

export type ItinerarySection = {
  id: string;
  type: ItinerarySectionType;
  title: string;
  day?: number;
  dateHeader?: {
    date: string;
    day: string;
  };
  items: ItineraryItem[];
};

// while we build a service, it may be incomplete
export type ItineraryServiceIncomplete = Partial<ItineraryService>;
export type ItineraryItemIncomplete = Partial<ItineraryItem>;
